import React, { useContext } from "react";
import { Context } from "../context";
import PrimaryButton from "../Button/PrimaryButton";
import LightGrayButton from "../Button/LightGrayButton";
import { GridIcon, CheckIcon } from "../Icons";
import {CompareIcon} from "../ProductStyle";


const AddToComparison = ({ product }) => {
  const { compareProducts, addProductToComparison } = useContext(Context);

  const pIds = compareProducts.map((p) => p.id);
  if (pIds.indexOf(product.id) >= 0) {
    return (
      <CompareIcon>
        <PrimaryButton icon={<CheckIcon/>} text="Compare" size="xs" />   
      </CompareIcon>
    );
  }

  return (
    <CompareIcon onClick={() => addProductToComparison("detail", product)}>
      <LightGrayButton icon={<GridIcon />} text="Compare" size="xs" />
    </CompareIcon>
  );
};

export default AddToComparison;