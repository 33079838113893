import React, { useContext } from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { Container, Section, SectionPageTitle } from "../components/Section";
import { Context } from "../components/context";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { CallIcon, CloseIcon, DetailIcon } from "../components/Icons";
import { getCommonItems } from "../utils";
import ProductCarousel from "../components/ProductCarousel";
import { graphql, Link } from "gatsby";
import DefaultButton from "../components/Button/DefaultButton";
import PrimaryLinkButton from "../components/Button/PrimaryLinkButton";

const TableCompare = styled.div`
  margin: 0;
  margin-bottom: 2rem;
`;
const TableResponsive = styled.div`
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
`;

const ComparisonTable = styled.table`
  table-layout: fixed;
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  tr {
    border: 0;
    border-bottom: 1px solid #f7f7f7;
  }
  tr:first-child {
    border-top: 1px solid #f7f7f7;
  }
  td:first-child {
    width: auto;
  }
  td.pro-heading {
    text-align: right;
    font-weight: 500;
    background: #e31d1d;
    color: #fff;
    border-right: 2px solid #f7f7f7;
    width: 100px;
    @media (min-width: 768px) {
      width: 150px;
    }
  }
  td.pro-title {
    padding: 8px 30px;
    font-size: 18px;
    line-height: 28px;
    font-weight: 700;
    background-color: #ebf6ff;
    text-align: left;
    color: #062c44;
  }
  td {
    padding: 8px 10px;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff;
    text-align: left;
    background: #deedfd;
    font-size: 14px;
    line-height: 22px;
    color: #062c44;
  }
  td.pro-desc {
    p {
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 0;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  td.product-thumb {
    text-align: center;
    .gatsby-image-wrapper,
    img {
      width: 200px;
      padding: 4px;
      background-color: #fff;
      border: 1px solid #ddd;
    }
  }
  td:nth-child(2n + 3) {
    background: #cae2fc;
  }
  td.action {
    min-width: 250px;
    text-align: center;
    .btn {
      font-size: 14px;
      line-height: 20px;
      padding: 3px 9px;
      font-weight: 400;
    }
  }
`;
const CloseWrap = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const CloseBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 22px;
  height: 22px;
  border-radius: 12px;
  border: 1px solid #062c44;
  svg {
    fill: #062c44;
  }
  &:hover {
    border-color: #ff3e00;
    svg {
      fill: #ff3e00;
    }
  }
`;

const ProductDesc = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: inherit;
  line-height: 28px;
  @media (min-width: 1200px) {
    font-size: inherit;
    line-height: 28px;
  }

  ul li, p {
    font-size: 14px;
    line-height: 20px;
  }
`;

const AddProduct = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  min-height: 120px;

  span {
    font-size: 16px;
    color: gray;
  }
`;

const AddProductIconWrapper = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid gray;
  span {
    font-size: 24px;
    font-weight: bold;
  }
`;
const ProductItem = styled.div`
  position: relative;
  height: 100%;
  padding: 4px;

  img {
    padding: 4px;
    line-height: 1.62857143;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 0;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    display: inline-block;
    max-width: 100%;
    height: auto;
  }
`;

const Title = styled.div`
  color: black;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 32px;
`;

const CompareProductActions = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
`;

const displayFields = [
  "Product",
  "Price",
  "SKU",
  "Building Specifications",
  "Description",
  "Location",
  "Gauge",
  "Manufacturer",
  "Roof Style",
  "Condition",
  "Building Tags",
  "Actions",
];

const ProductCompare = () => {
  const {
    comparisonData,
    compareProducts,
    addProductToComparison,
    selectProduct,
    removeProduct,
  } = useContext(Context);

  const renderCell = (field, product, no) => {
    if (!product) {
      if (field !== "Product") {
        if (!compareProducts || compareProducts.length === 0) return null;

        return <td key={no}></td>;
      }
    }

    switch (field) {
      case "Product":
        return (
          <td key={no}>
            {product ? (
              <ProductItem>
                <CloseWrap>
                  <CloseBtn onClick={() => removeProduct(product.id)}>
                    <CloseIcon />
                  </CloseBtn>
                </CloseWrap>
                <Title>{product.title}</Title>
                <GatsbyImage
                  image={product.image.gatsbyImageData}
                  alt={product.title}
                />
              </ProductItem>
            ) : (
              <AddProduct
                onClick={async () => {
                  const p = await selectProduct();
                  addProductToComparison("select", p, no);
                }}
              >
                <AddProductIconWrapper>
                  <span>+</span>
                </AddProductIconWrapper>
                <span>Add a Product</span>
              </AddProduct>
            )}
          </td>
        );
      case "Price":
        return (
          <td key={no}>
            <strong>${product.price.toLocaleString("en-US")}</strong>
          </td>
        );
      case "SKU":
        return <td key={no}>{product.skuNumber}</td>;

      case "Building Specifications":
        return (
          <td key={no}>
            <ProductDesc
              dangerouslySetInnerHTML={{
                __html: product.specifications.childMarkdownRemark.html,
              }}
            />
          </td>
        );
      case "Description":
        return (
          <td key={no}>
            <ProductDesc>
              {product.description.childMarkdownRemark.html.replace(
                /<[^>]+>/g,
                ""
              )}
            </ProductDesc>
          </td>
        );
      case "Location":
        return (
          <td key={no}>
            {product.city}, {product.state} {product.zipcode}
          </td>
        );
      case "Gauge":
        return (
          <td key={no}>
            {product["gaugeTubing"] || 0}-Gauge Tubing,{" "}
            {product["gaugePanels"] || 0}-Gauge Panels (Upgrade Available)
          </td>
        );
      case "Manufacturer":
        return <td key={no}>{product.manufacturer}</td>;
      case "Roof Style":
        return <td key={no}>{product.roofStyleType}</td>;
      case "Condition":
        return <td key={no}>{product.buildingCondition}</td>;
      case "Building Tags":
        return <td key={no}>{(product["buildingTags"] || []).join(", ")}</td>;
      case "Actions":
        return (
          <td key={no}>
            <CompareProductActions>
              <Link to="tel:4792235995" aria-label="phone number">
                <DefaultButton
                  icon={<CallIcon />}
                  text="Call Us Now"
                  size="sm"
                />
              </Link>
              <Link to={product.url}>
                <PrimaryLinkButton
                  icon={<DetailIcon />}
                  text="View Details"
                  size="sm"
                />
              </Link>
            </CompareProductActions>
          </td>
        );
      default:
        return null;
    }
  };

  const renderHeadLabel = (field) => {
    if (field === "Product") {
      return <td className="pro-heading">Product</td>;
    }

    if (!compareProducts || compareProducts.length === 0) return null;
    return <td className="pro-heading">{field}</td>;
  };

  return (
    <Container>
      <SectionPageTitle textAlign="left" className="h4">
        Product Comparison
      </SectionPageTitle>
      <TableCompare>
        <TableResponsive className="table-responsive">
          <ComparisonTable>
            <tbody>
              {displayFields.map((field, j) => (
                <tr key={j}>
                  {renderHeadLabel(field)}
                  {comparisonData.map((p, i) => renderCell(field, p, i))}
                </tr>
              ))}
            </tbody>
          </ComparisonTable>
        </TableResponsive>
      </TableCompare>
    </Container>
  );
};

const SimilarProducts = ({ allProducts }) => {
  const { compareProducts } = useContext(Context);

  if (!compareProducts || compareProducts.length === 0) return null;
  const categories = compareProducts.reduce((currentResult, product) => {
    return [...currentResult, ...product.productCategories.map((c) => c.title)];
  }, []);
  const compareProductIds = compareProducts.map((p) => p.id);
  const products = allProducts.filter((edge) => {
    if (compareProductIds.indexOf(edge.node.id) < 0) {
      if (
        getCommonItems(
          categories,
          edge.node.productCategories.map((pc) => pc.title)
        ).length > 0
      )
        return true;
    }
    return false;
  });

  if (products.length === 0) return null;

  return <ProductCarousel data={products} title="Similar Buildings" />;
};

const Page = ({ data, location }) => {
  const breadcrumbSchema = {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Home Page",
        item: "https://www.engineeredmetalbuildings.com/",
      },
      {
        "@type": "ListItem",
        position: 2,
        name: "Compare",
        item: `https://www.engineeredmetalbuildings.com/compare/`,
      },
    ],
  };
  const schema = [breadcrumbSchema];
  return (
    <Layout location={location}>
      <Seo
        title="Compare Metal Buildings - Prices, Specs & Features - EMB Metal Buildings"
        description="Compare metal buildings, prices, features, and specifications to find the perfect structure. Make an informed decision with our comprehensive comparison tool."
        schemaMarkup={schema}
      />
      <Section
        ept="130px"
        epb="0"
        xpt="130px"
        xpb="0"
        pt="100px"
        pb="0"
        bgColor="#fff"
        className="section-compare"
      >
        <ProductCompare />
      </Section>

      <SimilarProducts allProducts={data.allContentfulProduct.edges} />
    </Layout>
  );
};

export default Page;

export const pageQuery = graphql`
  query ComparePageQuery {
    allContentfulProduct(sort: { fields: skuNumber, order: ASC }) {
      edges {
        node {
          id
          url
          rto
          financingAvailable
          skuNumber
          title
          image {
            gatsbyImageData(quality: 50, placeholder: BLURRED)
            fluid {
              srcWebp
            }
          }
          price
          width
          length
          height
          city
          state
          zipcode
          location {
            lat
            lon
          }
          buildingCondition
          description {
            childMarkdownRemark {
              html
            }
          }
          specifications {
            childMarkdownRemark {
              html
            }
          }
          productCategories {
            title
          }
          buildingTags
          roofStyleType
          manufacturer
          gaugeTubing
          gaugePanels
        }
      }
    }
  }
`;
