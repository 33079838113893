import React, { useState } from "react";
import { graphql, Link, navigate } from "gatsby";
import styled from "styled-components";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import {
  Section,
  SectionPageTitle,
  Container,
  SectionImageBkg,
  SectionTitle,
  SectionDescription,
  BreadCrumb,
  ExploreMore,
} from "../components/Section";
import { PhoneChatIcon } from "../components/Icons";
import ProductCardGridView from "../components/ProductCardGridView";
import DefaultButton from "../components/Button/DefaultButton";
import PrimaryButton from "../components/Button/PrimaryButton";
import ProductSection from "../sections/Home/ProductSection";
import {
  FaqGrid,
  FaqLeftGrid,
  FaqRightGrid,
} from "../components/stateTemplate";
import FaqAccordion from "../components/FaqAccordion";

const HeroBanner = styled.section`
  position: relative;
  padding: 64px 0 0;
  @media (min-width: 768px) {
    margin-top: 64px;
    padding: 0;
    /* height: calc(100vh - 64px); */
  }
  @media (min-width: 1200px) {
    margin-top: 100px;
    padding: 0;
    /* height: calc(100vh - 100px); */
  }
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(25, 27, 38, 0.5);
  }
`;

const SectionImageBkgs = styled(SectionImageBkg)`
  /* @media (max-width: 767.98px) {
    position: inherit;
  } */
`;

const HeroCaption = styled.div`
  text-align: center;
  margin: 0;
  z-index: 1;
  position: relative;

  backdrop-filter: blur(20px);
  background-color: hsla(0, 0%, 100%, 0.32);
  width: 100%;
  height: 100%;
  padding: 40px 0;
  @media (min-width: 992px) {
    width: 50%;
  }
  @media (min-width: 1600px) {
    padding: 40px 25px;
  }
  /* .container {
    @media (min-width: 1200px) {
      height: calc(100% + 60px);
    }
  } */
`;

const HeroInnerCaption = styled.div``;

const HeroButton = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 0;
`;

const HeroTitle = styled(SectionPageTitle)`
  color: #fff;
  margin-bottom: 10px;
  font-size: 30px;
  line-height: 40px;
  @media (min-width: 576px) {
    font-size: 32px;
    line-height: 42px;
  }
  @media (min-width: 768px) {
    font-size: 34px;
    line-height: 44px;
  }
  @media (min-width: 992px) {
    font-size: 36px;
    line-height: 46px;
  }
  @media (min-width: 1200px) {
    font-size: 42px;
    line-height: 52px;
  }
  @media (min-width: 1600px) {
    font-size: 48px;
    line-height: 58px;
  }
`;

const HeroContent = styled.div`
  color: #fff;
  text-align: left;
  p {
    @media (min-width: 1200px) {
      font-size: 16px;
      line-height: 32px;
    }
    @media (min-width: 1600px) {
      font-size: 18px;
      line-height: 36px;
    }
  }
`;

const SectionCustom = styled(Section)`
  overflow: hidden;
  .container {
    @media (min-width: 1410px) {
      max-width: inherit;
      padding-right: 0;
      padding-left: 0;
    }
  }
`;
const Grid = styled.div`
  .grid-item {
    position: relative;
    @media (min-width: 1410px) {
      padding-right: calc((100% - 1350px) / 2);
      padding-left: 0;
    }
    &:nth-child(even) {
      flex-direction: row-reverse;
      @media (min-width: 1410px) {
        padding-right: 0;
        padding-left: calc((100% - 1350px) / 2);
      }
      .grid-figure {
        padding: 0 0 10px 10px;
        @media (min-width: 1600px) {
          padding: 0 0 20px 20px;
        }
        &:after {
          bottom: 0;
          right: auto;
          left: 0;
          background-color: #e9242a;
          clip-path: polygon(0 0, 0% 100%, 100% 100%);
        }
      }
      .grid-content {
        &:after {
          right: auto;
          left: 0;
          clip-path: polygon(0px 0px, 100% 0, 0px 100%);
        }
      }
    }
    + .grid-item {
      margin-top: 40px;
      @media (min-width: 768px) {
        margin-top: 60px;
      }
      @media (min-width: 992px) {
        margin-top: 80px;
      }
      @media (min-width: 1200px) {
        margin-top: 100px;
      }
      @media (min-width: 1600px) {
        margin-top: 120px;
      }
    }
  }
`;
const GridItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
  @media (min-width: 992px) {
    margin: 0 -30px;
  }
`;
const GridItemCol = styled.div`
  position: relative;
  width: 100%;
  padding: 0 20px;
  @media (min-width: 992px) {
    padding: 0 30px;
    flex: 0 0 50%;
    max-width: 50%;
  }
  &.grid-content {
    &:after {
      @media (min-width: 1200px) {
        content: "";
        position: absolute;
        right: 0;
        width: 28px;
        height: 28px;
        background-color: #373636;
        clip-path: polygon(0 0, 100% 100%, 100% 0);
        top: -40px;
      }
      @media (min-width: 1600px) {
        top: -60px;
      }
    }
  }
`;

const Figure = styled.div`
  position: relative;
  margin-bottom: 30px;
  padding: 0 10px 10px 0;
  @media (min-width: 1600px) {
    padding: 0 20px 20px 0;
  }
  .gatsby-image-wrapper {
    position: relative;
    z-index: 1;
    box-shadow: rgba(0, 0, 0, 0.35) 0 30px 40px -20px;
  }
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 40%;
    height: 60%;
    background-color: #576be4;
    -webkit-clip-path: polygon(100% 0, 0% 100%, 100% 100%);
    clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  }
`;

const ProductGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -10px;
  @media (min-width: 1600px) {
    margin: 0 -15px;
  }
`;
const ProductGridItem = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  @media (min-width: 768px) {
    max-width: 50%;
    flex: 0 0 50%;
  }
  @media (min-width: 1200px) {
    max-width: 33.33333%;
    flex: 0 0 33.33333%;
  }
  @media (min-width: 1600px) {
    padding: 0 15px;
  }
`;

const ProductCategoryTemplate = ({ location, data }) => {
  const [limit, setLimit] = useState(6);
  const pageData = data.contentfulProductCategories;
  const slug = pageData.title.split(" ").join("-").toLowerCase();

  const breadcrumbSchema = {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Home Page",
        item: "https://www.engineeredmetalbuildings.com/",
      },
      {
        "@type": "ListItem",
        position: 2,
        name: pageData.title,
        item: `https://www.engineeredmetalbuildings.com/${slug}/`,
      },
    ],
  };

  const schema = [breadcrumbSchema];

  const loadMore = () => {
    if (pageData.title === "Metal Buildings") {
      navigate("/shop/");
    } else {
      setLimit(limit + 6);
    }
  };

  let products = [];
  if (pageData.title === "Metal Buildings") {
    products = data.topSellingProducts.edges;
  } else {
    products = data.allContentfulProduct.edges;
  }

  const renderProducts = () => {
    if (pageData.title === "Metal Buildings") {
      return <ProductSection data={products} />;
    }

    return (
      <Section
        ept="30px"
        epb="60px"
        xpt="20px"
        xpb="40px"
        pt="15px"
        pb="30px"
        bgColor="#fff"
        className="section-product"
      >
        <Container>
          <SectionTitle textAlign="center" mb="15px">
            {pageData.productListHeading}
          </SectionTitle>
          <ProductGrid>
            {products.slice(0, limit).map((product, i) => (
              <ProductGridItem key={i} className="product-item">
                <ProductCardGridView product={product.node} />
              </ProductGridItem>
            ))}
          </ProductGrid>

          {limit < data.allContentfulProduct.edges.length && (
            <ExploreMore justifyContent="center">
              <button type="button" aria-label="button" aria-hidden="true">
                <DefaultButton
                  text={
                    pageData.title === "Metal Buildings"
                      ? "View More"
                      : "Load More"
                  }
                  size="xl"
                  onClick={() => loadMore()}
                />
              </button>
            </ExploreMore>
          )}
        </Container>
      </Section>
    );
  };

  return (
    <Layout location={location}>
      <Seo
        title={pageData.metaTitle}
        description={pageData.metaDescription}
        schemaMarkup={schema}
      />
      <HeroBanner>
        <SectionImageBkgs>
          <GatsbyImage
            image={pageData.heroImage.gatsbyImageData}
            alt="building-banner"
          />
          {/* <StaticImage src="../images/category-banner.png" alt="category-banner" />        */}
        </SectionImageBkgs>
        <HeroCaption>
          <Container className="container">
            <HeroInnerCaption>
              <BreadCrumb className="white">
                <Link to="/">Home</Link> <span>{pageData.title}</span>
              </BreadCrumb>
              <HeroTitle>
                {pageData.title} {pageData.subTitle}
              </HeroTitle>
              {/* <HeroSubTitle className="h1">{pageData.subTitle}</HeroSubTitle> */}
              <HeroContent>
                <div
                  dangerouslySetInnerHTML={{
                    __html: pageData.description.childMarkdownRemark.html,
                  }}
                />
              </HeroContent>
              <HeroButton>
                <a href="tel:4792235995" aria-label="phone number">
                  <PrimaryButton
                    icon={<PhoneChatIcon />}
                    text="(479) 223-5995"
                    size="xl"
                  />
                </a>
              </HeroButton>
            </HeroInnerCaption>
          </Container>
        </HeroCaption>
      </HeroBanner>

      {renderProducts()}

      <SectionCustom
        ept="60px"
        epb="30px"
        xpt="40px"
        xpb="20px"
        pt="30px"
        pb="15px"
        bgColor="#eef0fd"
        className="section-custom"
      >
        <Container className="container">
          <Grid className="grid">
            {pageData.sections.map((section, i) => (
              <GridItem className="grid-item" key={i}>
                <GridItemCol>
                  <Figure className="grid-figure">
                    <GatsbyImage
                      image={section.image.gatsbyImageData}
                      alt={section.title}
                    />
                  </Figure>
                </GridItemCol>

                <GridItemCol className="grid-content">
                  <SectionTitle>{section.title}</SectionTitle>
                  <SectionDescription>
                    <hr />
                    <div
                      dangerouslySetInnerHTML={{
                        __html: section.description.childMarkdownRemark.html,
                      }}
                    />
                  </SectionDescription>
                </GridItemCol>
              </GridItem>
            ))}
          </Grid>
        </Container>
      </SectionCustom>

      {!!data.contentfulProductCategories.faQs && (
        <Section
          ept="60px"
          epb="60px"
          xpt="40px"
          xpb="40px"
          pt="20px"
          pb="15px"
          bgColor="#fff"
          className="section-location"
        >
          <Container>
            <SectionTitle textAlign="center">
              Frequently Asked Questions
            </SectionTitle>
            <FaqGrid>
              <FaqLeftGrid>
                <FaqAccordion faqs={data.contentfulProductCategories.faQs} />
              </FaqLeftGrid>
              <FaqRightGrid>
                <StaticImage src="../images/faq-charator.png" alt="Faq" />
              </FaqRightGrid>
            </FaqGrid>
          </Container>
        </Section>
      )}
    </Layout>
  );
};

export default ProductCategoryTemplate;

export const pageQuery = graphql`
  query ProductCategoryTemplatePageQuery($id: String!, $title: String!) {
    contentfulProductCategories(id: { eq: $id }) {
      metaTitle
      metaDescription
      title
      subTitle
      description {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        gatsbyImageData(placeholder: BLURRED, quality: 100)
      }
      sections {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        image {
          gatsbyImageData(placeholder: BLURRED, quality: 50)
        }
      }
      productListHeading
      faQs {
        question
        answer {
          childMarkdownRemark {
            html
          }
        }
      }
    }
    allContentfulProduct(
      filter: { productCategories: { elemMatch: { title: { eq: $title } } } }
    ) {
      edges {
        node {
          id
          url
          rto
          financingAvailable
          skuNumber
          title
          image {
            gatsbyImageData(quality: 50, placeholder: BLURRED)
            fluid {
              srcWebp
            }
          }
          price
          width
          length
          height
          city
          state
          zipcode
          location {
            lat
            lon
          }
          buildingCondition
          description {
            childMarkdownRemark {
              html
            }
          }
          specifications {
            childMarkdownRemark {
              html
            }
          }
          productCategories {
            title
          }
          buildingTags
          roofStyleType
          manufacturer
          gaugeTubing
          gaugePanels
        }
      }
    }
    topSellingProducts: allContentfulProduct(
      sort: { fields: skuNumber, order: ASC }
      limit: 6
    ) {
      edges {
        node {
          id
          url
          rto
          financingAvailable
          skuNumber
          title
          image {
            gatsbyImageData(quality: 50, placeholder: BLURRED)
            fluid {
              srcWebp
            }
          }
          price
          width
          length
          height
          city
          state
          zipcode
          location {
            lat
            lon
          }
          buildingCondition
          description {
            childMarkdownRemark {
              html
            }
          }
          productCategories {
            title
          }
          buildingTags
          roofStyleType
          manufacturer
          gaugeTubing
          gaugePanels
        }
      }
    }
  }
`;
