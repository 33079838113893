import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import HeroSection from "../sections/Home/HeroSection";
import ProductSection from "../sections/Home/ProductSection";
import WelcomeSection from "../sections/Home/WelcomeSection";
import FacebookPosts from "../components/FacebookPosts";
import { Section, SectionTitle } from "../components/Section";

const IndexPage = ({ location, data }) => {
  return (
    <Layout location={location}>
      <Seo
        title="EMB Metal Buildings | High-Quality Structures for Your Every Need"
        description="At EMB Metal Buildings, we provide a wide range of strong, durable, and affordable structures to meet all your storage needs. Order your building today!"
      />
      <HeroSection />
      <ProductSection data={data.allContentfulProduct.edges} />
      <WelcomeSection />
      <Section
        ept="60px"
        epb="60px"
        xpt="40px"
        xpb="40px"
        pt="30px"
        pb="30px"
        bgColor="#fff"
      >
        <div className="container">
          <SectionTitle mb="20px" textAlign="center">
            Check Out Our Facebook Posts for the Latest Buzz!
          </SectionTitle>
          <FacebookPosts />
        </div>
      </Section>
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageQuery {
    allContentfulProduct(sort: { fields: skuNumber, order: ASC }, limit: 6) {
      edges {
        node {
          id
          url
          rto
          financingAvailable
          skuNumber
          title
          image {
            gatsbyImageData(quality: 50, placeholder: BLURRED)
            fluid {
              srcWebp
            }
          }
          price
          width
          length
          height
          city
          state
          zipcode
          location {
            lat
            lon
          }
          buildingCondition
          description {
            childMarkdownRemark {
              html
            }
          }
          specifications {
            childMarkdownRemark {
              html
            }
          }
          productCategories {
            title
          }
          buildingTags
          roofStyleType
          manufacturer
          gaugeTubing
          gaugePanels
        }
      }
    }
  }
`;
