import styled from "styled-components";

export const ProductCard = styled.div`
  position: relative;
  background-color: #fff;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 20px);
  margin-bottom: 20px;
  @media (min-width: 1600px) {
    margin-bottom: 30px;
    height: calc(100% - 30px);
  }
`;
ProductCard.defaultProps = {};
export const ProductBody = styled.div`
  position: relative;
  padding: 20px 10px;
  @media (min-width: 576px) {
    padding: 20px;
  }
  + .card-footer {
    padding-top: 0;
  }
`;
export const ProductFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  padding: 20px 10px;
  @media (min-width: 576px) {
    padding: 20px;
  }
`;
export const ProductFigure = styled.div`
  border-radius: 10px;
  overflow: hidden;
  position: relative;
`;
export const ProductDtl = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  .btn {
    display: flex;
    border-radius: 5px 0 0 0 !important;
    text-transform: capitalize;
  }
`;
export const ProductSku = styled.div`
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
  position: absolute;
  top: 0;
  z-index: 1;
  left: 20px;
  border-radius: 0 0 5px 5px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  padding: 4px 12px;
  &.sku-lg {
    padding: 8px 16px;
    border-radius: 0 0 15px 15px;
  }
`;
export const ProductCompare = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
  width: 40px;
  height: 40px;
  border-radius: 0 0 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: lightgray;
  cursor: pointer;

  svg {
    fill: #444444;
  }

  &:hover {
    background: #e5e5e5;
  }
`;

export const CompareIcon = styled.button`
  position: absolute;
  bottom: 0;
  left: 0;
  .btn {
    display: flex;
    border-radius: 0 5px 0 0 !important;
    text-transform: capitalize;
  }
`;

export const ProductName = styled.div`
  position: relative;
  z-index: 1;
  font-weight: 600;
  margin-bottom: 0;
  color: #373636;
  font-size: 16px;
  line-height: 24px;
  @media (min-width: 768px) {
    font-size: 18px;
    line-height: 24px;
  }
  @media (min-width: 992px) {
    font-size: 20px;
    line-height: 26px;
  }
  @media (min-width: 1200px) {
    font-size: 24px;
    line-height: 30px;
  }
  > a {
    color: #373636;
    &:hover {
      color: #000;
      text-decoration: underline;
    }
  }
`;
export const ProductDesc = styled.div`
  color: #000;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 28px;
  margin-bottom: 15px;
  @media (min-width: 1200px) {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 20px;
  }
  p {
    font-size: 14px;
    line-height: 30px;
    @media (min-width: 1200px) {
      font-size: 16px;
      line-height: 32px;
    }
  }
`;

export const ProductPrice = styled.div`
  font-weight: 500;
  color: #e9242a;
  margin-bottom: 15px;
  font-size: 16px;
  line-height: 24px;
  @media (min-width: 576px) {
    font-size: 18px;
    line-height: 24px;
  }
  @media (min-width: 1200px) {
    font-size: 20px;
    line-height: 26px;
  }
  @media (min-width: 1600px) {
    font-size: 22px;
    line-height: 28px;
  }
  small {
    color: #373636;
    font-size: 14px;
    line-height: 20px;
    @media (min-width: 576px) {
      font-size: 16px;
      line-height: 24px;
    }
    @media (min-width: 1200px) {
      font-size: 18px;
      line-height: 24px;
    }
  }
`;

export const ListInfo = styled.div`
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -5px;
  @media (min-width: 576px) {
    margin: 0 -10px;
  }
`;
export const ListItemInfo = styled.div`
  display: flex;
  flex-wrap: nowrap;
  padding: 0 5px;
  @media (min-width: 576px) {
    padding: 0 10px;
  }

  a {
    display: inline-block;
    color: #373636;

    position: relative;
    &:after,
    &:before {
      -webkit-transition: -webkit-transform 0.3s;
      transition: transform 0.3s;
      background: rgba(255, 255, 255, 0.4);
      content: "";
      position: absolute;
    }
    &:before {
      top: -webkit-calc(110% - 0.25em);
      top: calc(110% - 0.25em);
      left: -webkit-calc(50% - 0.3em);
      left: calc(50% - 0.3em);
      width: 0.6em;
      height: 0.6em;
      border-radius: 1.2em;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    &:after {
      top: 110%;
      left: 0;
      width: 100%;
      height: 2px;
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
    }

    &:hover {
      color: #e9242a;
      &:before {
        -webkit-transform: scale(1);
        transform: scale(1);
      }

      &:after {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
      }
    }
  }
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    > svg {
      fill: #e9242a;
      height: 14px;
      width: 14px;
    }
    + .text {
      margin-left: 5px;
    }
  }
  .text {
    color: #373636;
    font-size: 10px;
    line-height: 20px;
    @media (min-width: 576px) {
      font-size: 12px;
    }
    @media (min-width: 768px) {
      font-size: 14px;
    }
    strong {
      font-weight: 700;
      font-size: 12px;
      @media (min-width: 576px) {
        font-size: 14px;
      }
    }
  }
`;

export const ProductCta = styled.div`
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  margin: 10px -5px 0 -5px;
  @media (min-width: 1200px) {
    margin: 15px -10px 0 -10px;
  }
  a,
  button {
    width: 100%;
    .btn {
      width: 100%;
    }
  }

  .btn {
    text-transform: inherit;
    .icon + .text {
      padding-left: 0px;
    }
  }
`;
export const ProductItemCta = styled.div`
  position: relative;
  width: 100%;
  padding: 0 5px;
  @media (min-width: 576px) {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  @media (min-width: 1200px) {
    padding: 0 10px;
  }
`;

export const FoundationCard = styled.div`
  position: relative;
  background-color: #fff;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 20px);
  margin-bottom: 20px;
  @media (min-width: 1600px) {
    margin-bottom: 30px;
    height: calc(100% - 30px);
  }
`;
FoundationCard.defaultProps = {};
export const FoundationBody = styled.div`
  position: relative;
  padding: 20px 10px;
  @media (min-width: 576px) {
    padding: 20px;
  }
  + .card-footer {
    padding-top: 0;
  }
`;
export const FoundationFooter = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  flex-grow: 1;
  padding: 20px 10px;
  @media (min-width: 576px) {
    padding: 20px;
  }
`;
export const FoundationFigure = styled.div`
  border-radius: 10px;
  overflow: hidden;
  position: relative;
`;
export const FoundationName = styled.div`
  position: relative;
  z-index: 1;
  font-weight: 600;
  margin-bottom: 0;
  color: #373636;
  font-size: 16px;
  line-height: 24px;
  @media (min-width: 768px) {
    font-size: 18px;
    line-height: 24px;
  }
  @media (min-width: 992px) {
    font-size: 20px;
    line-height: 26px;
  }
  @media (min-width: 1200px) {
    font-size: 24px;
    line-height: 30px;
  }
  > a {
    color: #373636;
    &:hover {
      color: #000;
    }
  }
`;
export const FoundationDesc = styled.div`
  color: #000;
  font-size: 14px;
  line-height: 28px;
  @media (min-width: 1200px) {
    font-size: 16px;
    line-height: 28px;
  }
  p {
    font-size: 14px;
    line-height: 30px;
    margin-bottom: 0;
    @media (min-width: 1200px) {
      font-size: 16px;
      line-height: 32px;
    }
  }
`;
