import React from "react"
import styled from "styled-components"
import { PhoneChatIcon } from "../../components/Icons";
import DefaultButton from "../../components/Button/DefaultButton";

const PhoneCta = styled.div`
  background-color: #e9242a;
  padding: 20px;
  @media (min-width: 768px) {
    padding: 30px;
  }
  @media (min-width: 1200px) {
    padding: 30px 40px;
  }
  @media (min-width: 1600px) {
    padding: 40px 50px;
  }
`;
const PhoneSubTitle = styled.div`
  font-weight: 600;
  color: #fff;

  font-size: 16px;
  line-height: 28px;
  @media (min-width: 768px) {
    font-size: 18px;
    line-height: 30px;
  }
  @media (min-width: 992px) {
    font-size: 20px;
    line-height: 30px;
  }
  @media (min-width: 1200px) {
    font-size: 24px;
    line-height: 34px;
  }
`;
const PhoneTitle = styled.div`
  font-weight: 700;
  color: #fff;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 24px;
  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 30px;
  }
  @media (min-width: 992px) {
    font-size: 24px;
    line-height: 34px;
  }
  @media (min-width: 1200px) {
    font-size: 30px;
    line-height: 40px;
  }
`;
const PhoneAction = styled.div`
  a,
  .btn {
    display: flex;
    @media (min-width: 992px) {
      width: 100%;
    }
  }
  .btn {
    border: 1px solid #373636;
    .icon {
      > svg {
        width: 16px;
        @media (min-width: 992px) {
          width: 20px;
        }
        @media (min-width: 1200px) {
          width: 24px;
        }
        @media (min-width: 1600px) {
          width: 28px;
        }
      }
    }
    .text {
      @media (min-width: 1200px) {
        > .text {
          font-size: 24px;
          line-height: 30px;
        }
      }
      @media (min-width: 1600px) {
        > .text {
          font-size: 30px;
          line-height: 34px;
        }
      }
    }
    &:hover {
      border-color: #fff;
    }
  }
`;
const PhoneCtaSection = (props) => {
  const { subTitle, phoneTitle } = props;
	return (
    <PhoneCta className="phone-cta">
      <PhoneSubTitle>{subTitle}</PhoneSubTitle>
      <PhoneTitle>{phoneTitle}</PhoneTitle>
      <PhoneAction><a href="tel:4792235995" aria-label="phone number"><DefaultButton icon={<PhoneChatIcon />} text="Call Us Now" /></a></PhoneAction>
    </PhoneCta>
	)
}

export default PhoneCtaSection
