import React, { useContext, useEffect, useState } from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  MapIcon,
  QuoteIcon,
  CallIcon,
  ConditionIcon,
  DetailIcon,
  GridIcon,
} from "../Icons";
import {
  ProductCard,
  ProductBody,
  ProductFooter,
  ProductSku,
  ProductFigure,
  ProductName,
  ProductDesc,
  ProductPrice,
  ProductCta,
  ProductItemCta,
  ListInfo,
  ListItemInfo,
  CompareIcon,
} from "../ProductStyle";
import DefaultButton from "../Button/DefaultButton";
import PrimaryLinkButton from "../Button/PrimaryLinkButton";
import LightGrayButton from "../Button/LightGrayButton";
import styled from "styled-components";
import { Context } from "../context";
import CheckIcon from "../Icons/CheckIcon";
import PrimaryButton from "../Button/PrimaryButton";

const HiddenDiv = styled.div`
  display: none;
`;

const ProductCardGridView = ({ product }) => {
  const [description, setDescription] = useState("");
  const { compareProducts, addProductToComparison, removeProduct } =
    useContext(Context);

  useEffect(() => {
    if (!document) return;
    const descDiv = document.getElementById(product.id);
    if (descDiv && !description) {
      setDescription(descDiv.textContent);
    }
  }, []);

  const pIds = compareProducts.map((p) => p.id);

  return (
    <ProductCard className="card card-grid">
      <HiddenDiv
        id={product.id}
        dangerouslySetInnerHTML={{
          __html: product.description?.childMarkdownRemark.html,
        }}
      />
      <ProductBody className="card-body">
        <ProductFigure className="card-figure">
          <ProductSku className="product-sku">
            SKU : {product.skuNumber}
          </ProductSku>
          <Link to={product.url}>
            <GatsbyImage
              image={product.image.gatsbyImageData}
              alt={product.title}
            />
          </Link>
          {/* <ProductDtl className="product-dtl">
            <Link to={product.url}>
              <DefaultButton icon={<DetailIcon />} text="Details" size="xs" />
            </Link>
          </ProductDtl> */}
          {pIds.indexOf(product.id) >= 0 ? (
            <CompareIcon onClick={() => removeProduct(product.id)}>
              <PrimaryButton icon={<CheckIcon />} text="Compare" size="xs" />
            </CompareIcon>
          ) : (
            <CompareIcon
              onClick={() => addProductToComparison("card", product)}
            >
              <LightGrayButton icon={<GridIcon />} text="Compare" size="xs" />
            </CompareIcon>
          )}
        </ProductFigure>
      </ProductBody>
      <ProductFooter className="card-footer">
        <div>
          <ProductName className="product-name">
            <Link to={product.url}>{product.title}</Link>
          </ProductName>
          <ProductPrice className="product-price">
            <small>Starting Price:</small> $
            {product.price.toLocaleString("en-US")}*
          </ProductPrice>
          <ProductDesc className="product-desc">
            <p>{description}</p>
          </ProductDesc>
        </div>
        <div>
          <ListInfo className="list-info">
            <ListItemInfo className="list-item">
              <span className="icon">
                <ConditionIcon />
              </span>
              <span className="text">
                Condition: <strong>{product.buildingCondition}</strong>
              </span>
            </ListItemInfo>
            <ListItemInfo className="list-item">
              <span className="icon">
                <MapIcon />
              </span>
              <span className="text">
                Location:{" "}
                <strong>
                  {product.city}, {product.state} {product.zipcode}
                </strong>
              </span>
            </ListItemInfo>
          </ListInfo>
          <ProductCta>
            <ProductItemCta className="product-item-cta">
              <a href="tel:4792235995" aria-label="phone number">
                <DefaultButton
                  icon={<CallIcon />}
                  text="Call Us Now"
                  size="sm"
                />
              </a>
            </ProductItemCta>
            <ProductItemCta className="product-item-cta">
              <Link to={product.url}>
                <PrimaryLinkButton
                  icon={<DetailIcon />}
                  text="View Details"
                  size="sm"
                />
              </Link>
              {/* <QuotePopUpButton
                thanksURL="/thank-you-product-quote/"
                text="Get a Quote"
                iconAfter={<QuoteIcon />}
                size="sm"
                btnStyle="btnStyle"
              /> */}
            </ProductItemCta>
          </ProductCta>
        </div>
      </ProductFooter>
    </ProductCard>
  );
};
export default ProductCardGridView;
