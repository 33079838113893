import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import {
  Section,
  Container,
  SectionLineDescription,
  SectionPageTitle,
} from "../components/Section";
import ProductCarousel from "../components/ProductCarousel";

const ManufacturesPage = ({ location, data }) => {
  const breadcrumbSchema = {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Home Page",
        item: "https://www.engineeredmetalbuildings.com/",
      },
      {
        "@type": "ListItem",
        position: 2,
        name: "Manufactures",
        item: `https://www.engineeredmetalbuildings.com/manufactures/`,
      },
    ],
  };
  const schema = [breadcrumbSchema];
  return (
    <Layout location={location}>
      <Seo
        title="Manufactures"
        description="Manufactures"
        schemaMarkup={schema}
      />
      <Section
        ept="160px"
        epb="40px"
        xpt="140px"
        xpb="40px"
        pt="120px"
        pb="30px"
        bgColor="#fff"
        className="section-manufacturer"
      >
        <SectionLineDescription mb="0" textAlign="center">
          <Container>
            <SectionPageTitle textAlign="center">
              COAST TO COAST CARPORTS
            </SectionPageTitle>
            <p>
              Coast to Coast Carports is a proud manufacturer of premium steel
              buildings. From small equipment sheds to enormous warehouse
              facilities, we have the skills and experience to get the job done.
              Our buildings are incredibly durable, cost-effective, and can be
              constructed in a fraction of the time it takes to build a
              similar-sized wooden structure. So, it doesn't matter whether you
              require a carport, an RV cover, or an enclosed space such as our
              garages, metal barns, and warehouses, Coast to Coast Carports is
              your one-stop solution for all things metal.
            </p>
          </Container>
        </SectionLineDescription>
      </Section>
      <ProductCarousel data={data.allContentfulProduct.edges} />
    </Layout>
  );
};
export default ManufacturesPage;

export const pageQuery = graphql`
  query AboutUsPagePageQuery {
    allContentfulProduct(sort: { fields: skuNumber, order: ASC }) {
      edges {
        node {
          id
          url
          rto
          financingAvailable
          skuNumber
          title
          image {
            gatsbyImageData(quality: 50, placeholder: BLURRED)
            fluid {
              srcWebp
            }
          }
          price
          width
          length
          height
          city
          state
          zipcode
          location {
            lat
            lon
          }
          buildingCondition
          description {
            childMarkdownRemark {
              html
            }
          }
          specifications {
            childMarkdownRemark {
              html
            }
          }
          productCategories {
            title
          }
          buildingTags
          roofStyleType
          manufacturer
          gaugeTubing
          gaugePanels
        }
      }
    }
  }
`;
