import React from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";
import {
  Section,
  Container,
  SectionTitle,
  SectionDescription,
  SectionLineDescription,
  SectionImageBkg,
  SectionPageTitle,
} from "../components/Section";
import { HeroBanner, HeroImage } from "../components/HeroBanner";
import FoundationCard from "../components/FoundationCard";
import SiteBanner from "../images/site-banner.jpg";

const SectionCustom = styled(Section)`
  overflow: hidden;
  .container {
    @media (min-width: 1410px) {
      max-width: inherit;
      padding-right: 0;
      padding-left: 0;
    }
  }
`;
const Grid = styled.div`
  .grid-item {
    position: relative;
    @media (min-width: 1410px) {
      padding-right: calc((100% - 1350px) / 2);
      padding-left: 0;
    }
    .grid-figure {
      @media (min-width: 768px) {
        border-radius: 0 130px 130px 0;
      }
      @media (min-width: 992px) {
        border-radius: 0 230px 230px 0;
      }
    }
    &:nth-child(even) {
      @media (min-width: 768px) {
        flex-direction: row-reverse;
      }
      @media (min-width: 1410px) {
        padding-left: calc((100% - 1350px) / 2);
        padding-right: 0;
      }
      .grid-figure {
        @media (min-width: 768px) {
          border-radius: 130px 0 0 130px;
        }
        @media (min-width: 992px) {
          border-radius: 230px 0 0 230px;
        }
      }
      .grid-content {
        &:after {
          right: 0;
          left: auto;
          clip-path: polygon(0px 0px, 100% 100%, 100% 0);
        }
      }
    }
    + .grid-item {
      margin-top: 30px;
      @media (min-width: 992px) {
        margin-top: 40px;
      }
      @media (min-width: 1200px) {
        margin-top: 60px;
      }
      @media (min-width: 1600px) {
        margin-top: 80px;
      }
    }
  }
`;
const GridItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -15px;
  flex-direction:column-reverse;
  @media (min-width: 768px) {
    margin: 0 -20px;
    flex-direction:row;
  }
  @media (min-width: 992px) {
    margin: 0 -30px;
  }
`;
const GridItemLeft = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  @media (min-width: 768px) {
    padding: 0 20px;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  @media (min-width: 992px) {
    padding: 0 30px;
    flex: 0 0 40%;
    max-width: 40%;
  }
`;
const GridItemRight = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  @media (min-width: 768px) {
    padding: 0 20px;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  @media (min-width: 992px) {
    padding: 0 30px;
    flex: 0 0 60%;
    max-width: 60%;
  }
`;

const Figure = styled.div`
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow:hidden;
  margin-bottom:0;
  .gatsby-image-wrapper {
    display: inline-block;
    position: relative;
    z-index: 1;
    width: 100%;
  }
`;

const SitePreparationPage = ({ location, data }) => {
  const breadcrumbSchema = {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    itemListElement: [
        {
        "@type": "ListItem",
        position: 1,
        name: "Home Page",
        item: "https://www.engineeredmetalbuildings.com/",
        },
        {
        "@type": "ListItem",
        position: 2,
        name: "Site Preparation",
        item: `https://www.engineeredmetalbuildings.com/site-preparation/`,
        },
    ],
  };
  const schema = [breadcrumbSchema]
  return (
    <Layout location={location}>
      <Seo
        title="Site Preparation for Steel Building Installation - EMB Metal Buildings"
        description="Get detailed site preparation guidance from EMB Metal Buildings. We help you set a proper foundation for a successful steel building installation."
        schemaMarkup={schema}
      />
      <HeroBanner>
        <HeroImage bgImg={`url(${SiteBanner})`} opacity="1"></HeroImage>
      </HeroBanner>
      <Section
        ept="60px"
        epb="0"
        xpt="40px"
        xpb="0"
        pt="30px"
        pb="0"
        bgColor="#fff"
        className="section-about"
      >
        <SectionLineDescription mb="0" textAlign="center">
          <Container>
            <SectionPageTitle textAlign="center">Site Preparation Guide for Your Metal Building </SectionPageTitle>
            <SectionDescription textAlign="center" mb="0" maxWidth="1200px">
            <p>Proper site preparation is important to ensure the stability and longevity of your metal building. Below, we have outlined a simple step-by-step process to prepare your site before your building is installed. Our guide is designed to help you understand each phase and make the preparation as smooth as possible.</p>
            </SectionDescription>
          </Container>
        </SectionLineDescription>
      </Section>

      <SectionCustom
        ept="60px"
        epb="60px"
        xpt="40px"
        xpb="40px"
        pt="30px"
        pb="30px"
        bgColor="#fff"
        className="section-custom"
      >
        <Container className="container">
          <Grid className="grid">
            <GridItem className="grid-item">
              <GridItemLeft>
                <Figure className="grid-figure">
                  <StaticImage src="../images/step-1.jpg" alt="Choose the Location" />
                </Figure>
              </GridItemLeft>
              <GridItemRight className="grid-content">
                <h3 className="h4">Step 1: Choose the Location</h3>
                <SectionDescription>
                  <hr />
                  <p>Start by selecting the ideal spot for your metal building. Consider ease of access, the direction of sunlight, and drainage. Ensure the location is practical for your intended use, whether it's for storage, a workshop, or any other purpose.</p>
                </SectionDescription>
              </GridItemRight>
            </GridItem>

            <GridItem className="grid-item">
              <GridItemLeft className="grid-content">
                <Figure className="grid-figure">
                  <StaticImage src="../images/step-2.jpg" alt="Get Permission to Build" />
                </Figure>
              </GridItemLeft>
              <GridItemRight>
                <h3 className="h4">Step 2: Get Permission to Build</h3>
                <SectionDescription>
                  <hr />
                  <p>Before you begin any construction, it’s important to obtain all necessary building permits. Check with your local building department to understand the specific requirements and regulations in your area. This will help you avoid any legal or regulatory issues down the road.</p>
                </SectionDescription>
              </GridItemRight>
            </GridItem>

            <GridItem className="grid-item">
              <GridItemLeft className="grid-content">
                <Figure className="grid-figure">
                  <StaticImage src="../images/step-3.jpg" alt="Remove Brush and Other Debris" />
                </Figure>
              </GridItemLeft>
              <GridItemRight>
                <h3 className="h4">Step 3: Remove Brush and Other Debris</h3>
                <SectionDescription>
                  <hr />
                  <p>Clear the building site of any vegetation, rocks, and debris. This step is important to prevent any obstructions during the building process and to ensure a clean and safe working area.</p>
                </SectionDescription>
              </GridItemRight>
            </GridItem>
            
            
            <GridItem className="grid-item">
              <GridItemLeft className="grid-content">
                <Figure className="grid-figure">
                  <StaticImage src="../images/step-4.jpg" alt="Grade the Soil" />
                </Figure>
              </GridItemLeft>
              <GridItemRight>
                <h3 className="h4">Step 4: Grade the Soil</h3>
                <SectionDescription>
                  <hr />
                  <p>Level the ground where your building will be installed. Proper grading ensures that the site is flat and helps prevent water from pooling under or around the structure. This can significantly extend the life of your building.</p>
                </SectionDescription>
              </GridItemRight>
            </GridItem>

            <GridItem className="grid-item">
              <GridItemLeft className="grid-content">
                <Figure className="grid-figure">
                  <StaticImage src="../images/step-5.jpg" alt="Install Utilities" />
                </Figure>
              </GridItemLeft>
              <GridItemRight>
                <h3 className="h4">Step 5: Install Utilities</h3>
                <SectionDescription>
                  <hr />
                  <p>If your building requires electricity, water, or other utilities, now is the time to install them. It’s easier to lay pipes and cables before the building is constructed. Consider consulting a professional to ensure that all utilities are installed safely and correctly.</p>
                </SectionDescription>
              </GridItemRight>
            </GridItem>
          </Grid>
        </Container>
      </SectionCustom>
      <Section
        ept="60px"
        epb="60px"
        xpt="40px"
        xpb="40px"
        pt="30px"
        pb="30px"
        bgColor="#fff"
        className="section-foundation"
      >
        <Container className="container">
          <SectionTitle textAlign="center">Build a Foundation</SectionTitle>
          <SectionDescription textAlign="center"> 
            <p>A strong foundation is critical for the stability of your metal building. There are several types of foundations you can consider:</p>
          </SectionDescription>
          <FoundationCard />
        </Container>
      </Section>
      <Section
        ept="60px"
        epb="60px"
        xpt="40px"
        xpb="40px"
        pt="30px"
        pb="30px"
        bgColor="#eef0fd"
        className="section-installation"
      >
        {/* <SectionImageBkg><StaticImage src="../images/delivery-truck.jpg" alt="We Are Ready for Delivery and Installation" /></SectionImageBkg> */}
        <Container className="container">
          <SectionTitle textAlign="center">We Are Ready for Delivery and Installation</SectionTitle>
          <SectionDescription textAlign="center"> 
            <p>With your site fully prepared, you're now ready to move forward with the installation of your metal building. Each step in the site preparation process plays a vital role in ensuring the longevity and functionality of your structure. If you have any questions or need further assistance, our team at EMB Metal Buildings is here to help.</p>
          </SectionDescription>
        </Container>
      </Section>
    </Layout>
  );
};
export default SitePreparationPage;
