import React from "react";
import Seo from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import Layout from "../components/layout";
import FaqAccordionStatic from "../components/FaqAccordionStatic";
import {
  Section,
  SectionPageTitle,
  Container,
  SectionDescription,
} from "../components/Section";
import {
  Sidebar,
  SidebarDefault,
  SidebarDefaultTitle,
  SidebarDefaultBody,
  BuildingTexture,
} from "../components/Sidebar";
import PhoneCta from "../components/PhoneCta";
import TopSellingProduct from "../components/TopSellingProduct";
import { graphql } from "gatsby";
// import Recommendations from "../components/Recommendations";

const MainPanel = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  @media (min-width: 1200px) {
    margin: 0 -30px;
  }
`;
const LeftPanel = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  @media (min-width: 992px) {
    flex: 0 0 60%;
    max-width: 60%;
  }
  @media (min-width: 1200px) {
    padding: 0 30px;
  }
`;
const RightPanel = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  @media (min-width: 992px) {
    flex: 0 0 40%;
    max-width: 40%;
  }
  @media (min-width: 1200px) {
    padding: 0 30px;
  }
`;
const ProductGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;

  .card {
    align-items: center;
    .card-figure {
      border-radius: 5px;
    }
    margin-bottom: 15px;
    height: calc(100% - 15px);
    .product-name {
      font-size: 16px;
      line-height: 24px;
    }
    .product-sku {
      left: 10px;
      padding: 2px 6px;
      border-radius: 0px 0px 3px 3px;
      font-size: 12px;
      line-height: 14px;
    }
    .product-dtl {
    }
    .product-price {
      margin-bottom: 0;
      font-size: 14px;
      line-height: 20px;
      @media (min-width: 576px) {
        font-size: 16px;
        line-height: 24px;
      }
      small {
        color: #373636;
        font-size: 12px;
        line-height: 20px;
        @media (min-width: 576px) {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
    .card-footer {
      justify-content: flex-start;
    }
  }
  @media (min-width: 576px) {
    &.grid-list {
      flex-direction: column;
      .product-item {
        max-width: 100%;
        flex: 0 0 100%;
      }
      .card {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        text-align: left;
        .card-body {
          flex: 0 0 43%;
          max-width: 43%;
          padding: 10px;
        }
        .card-footer {
          flex-basis: 0;
          flex-grow: 1;
          max-width: 100%;
          padding: 10px;
        }
      }
    }
  }
`;
const ProductGridItem = styled.div`
  position: relative;
  z-index: 100;
  width: 100%;
  padding: 0 10px;
  @media (min-width: 768px) {
    max-width: 50%;
    flex: 0 0 50%;
  }
`;

const FaqsPage = ({ location, data }) => {
  const topSellingProducts = data.allContentfulProduct.edges;
  const faqs = data.allContentfulFaq.edges;

  let mainEntity = [];
  faqs.forEach(function (item) {
    mainEntity.push({
      "@type": "Question",
      name: item.node.question,
      acceptedAnswer: {
        "@type": "Answer",
        text: (item.node.answer.childMarkdownRemark.html || "").replace(
          /<[^>]+>/g,
          ""
        ),
      },
    });
  });
  const breadcrumbSchema = {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Home Page",
        item: "https://www.engineeredmetalbuildings.com/",
      },
      {
        "@type": "ListItem",
        position: 2,
        name: "FAQ's",
        item: `https://www.engineeredmetalbuildings.com/faqs/`,
      },
    ],
  };
  const faqSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: mainEntity,
  };
  const schema = [breadcrumbSchema, faqSchema];
  //console.log(mainEntity, 'Test');

  return (
    <Layout location={location}>
      <Seo
        schemaMarkup={schema}
        title="Steel Building FAQs | Metal Building Information"
        description="Have questions about metal buildings? Here you will find all the answers to your questions related to metal buildings, delivery, and installation. "
      />
      <Section
        ept="180px"
        epb="60px"
        xpt="140px"
        xpb="40px"
        pt="130px"
        pb="30px"
        bgColor="#fff"
        className="section-state"
      >
        <Container>
          <SectionPageTitle
            className="h2"
            textAlign="center"
            textTransform="capitalize"
          >
            Frequently Asked Questions
          </SectionPageTitle>
          <SectionDescription textAlign="center" maxWidth="1120px">
            <p>
              Buying a metal building is a big step, and you might have a few
              questions along the way. To help make your metal building purchase
              easier and hassle-free, we've answered some frequently asked
              questions about
            </p>
          </SectionDescription>
          <MainPanel>
            <LeftPanel>
              <FaqAccordionStatic faqs={faqs} />
            </LeftPanel>
            <RightPanel>
              <Sidebar className="isSticky">
                <SidebarDefault>
                  <SidebarDefaultBody>
                    <SidebarDefaultTitle color="#fff">
                      Top-Selling Metal Buildings
                    </SidebarDefaultTitle>
                    <BuildingTexture>
                      <StaticImage
                        src="../images/line-texture.png"
                        alt="specials building"
                      />
                    </BuildingTexture>
                    <ProductGrid className="grid-list">
                      {topSellingProducts.map((p, i) => (
                        <ProductGridItem key={i} className="product-item">
                          <TopSellingProduct data={p.node} />
                        </ProductGridItem>
                      ))}
                    </ProductGrid>
                  </SidebarDefaultBody>
                  <PhoneCta
                    subTitle="Call Now to Learn About Other"
                    phoneTitle="Discounts & Specials on these Buildings."
                  />
                </SidebarDefault>
                {/* <Recommendations /> */}
              </Sidebar>
            </RightPanel>
          </MainPanel>
        </Container>
      </Section>
    </Layout>
  );
};

export default FaqsPage;

export const pageQuery = graphql`
  query MetalBuildingPageQuery {
    allContentfulProduct(sort: { fields: skuNumber, order: ASC }, limit: 4) {
      edges {
        node {
          id
          url
          rto
          financingAvailable
          skuNumber
          title
          image {
            gatsbyImageData(quality: 50, placeholder: BLURRED)
            fluid {
              srcWebp
            }
          }
          price
          width
          length
          height
          city
          state
          zipcode
          location {
            lat
            lon
          }
          buildingCondition
          description {
            childMarkdownRemark {
              html
            }
          }
          specifications {
            childMarkdownRemark {
              html
            }
          }
          productCategories {
            title
          }
          buildingTags
          roofStyleType
          manufacturer
          gaugeTubing
          gaugePanels
        }
      }
    }
    allContentfulFaq(filter: { type: { eq: "common" } }) {
      edges {
        node {
          question
          answer {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
