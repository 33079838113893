import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image';
import {Nodata, NodataTitle, NodataDesc, NodataMedia, NodataGrid, NodataItem} from "../components/Nodata"
import { Section } from "../components/Section"
import PrimaryButton from "../components/Button/PrimaryButton"


const NotFoundPage = ({ location }) => {
  return(
    <Layout location={location}>
      <Seo title="404: Not Found" description="404: Not Found" />
      <Section ept="240px" epb="120px" xpt="120px" xpb="80px" pt="120px" pb="40px" bgColor="#fff">
        <div className="container">
          <Nodata>
            <NodataGrid>
              <NodataItem>
                <NodataMedia>
                  <StaticImage src="../images/error.png" alt="error" />
                </NodataMedia>
              </NodataItem>
              <NodataItem>
                <NodataTitle className="h2 mb-15">Page Not Found</NodataTitle>
                <NodataDesc mb="30px">
                    <p>Sorry, we can’t find the page you are looking for click here to go back to the home page.</p>
                    <Link to="/"><PrimaryButton text="Go To Home" size="md" /></Link>
                </NodataDesc>
              </NodataItem>
            </NodataGrid>
          </Nodata>
        </div>
      </Section>
    </Layout>
  )
}
export default NotFoundPage