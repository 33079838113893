import React from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";
import { Section,Container,SectionLineDescription } from "../components/Section";
import { HeroBanner, HeroImage } from "../components/HeroBanner";
import ColorBanner from "../images/color-banner.jpg";

const Figure = styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);    
    z-index:1;
    width:62%;
`

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom:20px;
  @media (min-width: 992px) {
    margin-bottom:30px;
  }
  @media (min-width: 1200px) {
    margin-bottom:40px;
  }
  @media (min-width: 1600px) {
    margin-bottom:60px;
  }
`
const GridLeft = styled.div`
    width:100%;
    flex:0 0 50%;
    max-width:50%;
    @media (max-width: 767.98px) {
        display:none;
    }
`
const GridRight = styled.div`
    position:relative;
    width:100%;
    
    background: linear-gradient(to bottom,  rgba(55,54,54,1) 0%,rgba(15,15,15,1) 100%);
    border-radius: 40px;
    padding: 20px;
    @media (min-width: 576px) {
        padding: 30px 20px;
    }
    @media (min-width: 768px) {
        flex:0 0 50%;
        max-width:50%;
        padding: 40px;
        border-radius: 50px;
    }
    @media (min-width: 992px) {
        padding: 50px;
        border-radius: 60px;
    }
    @media (min-width: 1200px) {
        border-radius: 80px;
    }
    @media (min-width: 1600px) {
        border-radius: 120px;
    }
`
const HelpText = styled.div`
    font-size: 14px;
    line-height: 24px;
    max-width: 980px;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    strong{
        font-weight:700;
    }
`
const ColorList = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin:0 -10px;
    position: relative;
    z-index: 1;
`
const ColorItemList = styled.div`
    position:relative;
    flex:0 0 33.33333%;
    max-width:33.33333%;
    padding:12px 10px; 
    @media (min-width: 576px) {
        padding:12px 10px; 
        flex:0 0 25%;
        max-width:25%;
    }
    @media (min-width: 1600px) {
        padding:15px 10px;
    }
    &.empty{
        @media (max-width: 767.98px) {
            display:none;
        }
    }
`
const ColorBox = styled.div`
    display: flex;
    flex-direction:column;    
    .color-image{
        display: flex;
        justify-content:center;
        margin-bottom:10px;

        transform: perspective(1px) translateZ(0);
        transition-duration: 0.3s;
        transition-property: transform;
        &:hover{
            transform: translateY(-15px);
        }

        .gatsby-image-wrapper{
            position:relative;
            overflow:hidden;
            filter: drop-shadow(rgba(0, 0, 0, 0.5) 5px 5px 5px);
            /* &:after {
                background: #fff;
                content: "";
                height: 155px;
                left: -155px;
                opacity: .2;
                position: absolute;
                top: -50px;
                transform: rotate(35deg);
                transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
                width: 50px;
                z-index: -10;
            }
            &:hover{
                &:after {
                    left: 120%;
                    transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
                }
            } */
        }
    }
    .color-name{
        display: flex;
        justify-content:center;
        font-size: 12px;
        line-height:18px;
        white-space: nowrap;
        color: #fff;
        @media (min-width: 576px) {
            font-weight:700;
        }
    }
`
const ColorPage = ({ location, data }) => {
    const breadcrumbSchema = {
        "@context": "https://schema.org/",
        "@type": "BreadcrumbList",
        itemListElement: [
            {
            "@type": "ListItem",
            position: 1,
            name: "Home Page",
            item: "https://www.engineeredmetalbuildings.com/",
            },
            {
            "@type": "ListItem",
            position: 2,
            name: "Color Options",
            item: `https://www.engineeredmetalbuildings.com/color-options/`,
            },
        ],
    };
    const schema = [breadcrumbSchema]
    return (
        <Layout location={location}>
        <Seo
            title="Metal Building Color Options | EMB Metal Buildings"
            description="Explore variety of color options for your metal building. Customize your metal building with 17+ colors from EMB Metal Buildings. Choose your style today!"
            schemaMarkup={schema}
        />
        <HeroBanner>
            <HeroImage bgImg={`url(${ColorBanner})`}></HeroImage>
        </HeroBanner>
        <Section
            ept="60px"
            epb="60px"
            xpt="40px"
            xpb="40px"
            pt="30px"
            pb="30px"
            bgColor="#fff"
            className="section-about"
        >
            <SectionLineDescription mb="0" textAlign="center">
            <Container>
                <h1>Color Options for Your Metal Building</h1>
                <p>At EMB Metal Buildings, we offer a variety of colors to make your structure stand out. Whether it’s a garage, barn, or carport, our color options let you personalize your building while ensuring lasting protection. Pick a color that matches your style and enhances your surroundings!</p>
            </Container>
            </SectionLineDescription>
        </Section>

        <Section       
            ept="0"
            epb="60px"
            xpt="0"
            xpb="40px"
            pt="0"
            pb="30px"
            bgColor="#fff"
            className="section-color"
        >
            <Container className="container">
            <Grid className="grid">
                <GridLeft className="color-media">
                    <Figure className="color-figure">
                    <StaticImage src="../images/building.png" alt="building" />
                    </Figure>
                </GridLeft>
                <GridRight className="color-box">
                    <ColorList className="color-list">
                        <ColorItemList className="color-item">
                            <ColorBox>
                                <span className="color-image">
                                    <StaticImage src="../images/galvalume.png" alt="galvalume" />
                                </span>
                                <span className="color-name">Galvalume</span>
                            </ColorBox>
                        </ColorItemList>
                        <ColorItemList className="color-item">
                            <ColorBox>
                                <span className="color-image">
                                    <StaticImage src="../images/burgundy.png" alt="Burgundy" />
                                </span>
                                <span className="color-name">Burgundy</span>
                            </ColorBox>
                        </ColorItemList>
                        <ColorItemList className="color-item">
                            <ColorBox>
                                <span className="color-image">
                                    <StaticImage src="../images/slate-blue.png" alt="Slate Blue" />
                                </span>
                                <span className="color-name">Slate Blue</span>
                            </ColorBox>
                        </ColorItemList>
                        <ColorItemList className="color-item">
                            <ColorBox>
                                <span className="color-image">
                                    <StaticImage src="../images/barn-red.png" alt="Barn Red" />
                                </span>
                                <span className="color-name">Barn Red</span>
                            </ColorBox>
                        </ColorItemList>
                        <ColorItemList className="color-item">
                            <ColorBox>
                                <span className="color-image">
                                    <StaticImage src="../images/pebble-beige.png" alt="Pebble Beige" />
                                </span>
                                <span className="color-name">Pebble Beige</span>
                            </ColorBox>
                        </ColorItemList>
                        <ColorItemList className="color-item">
                            <ColorBox>
                                <span className="color-image">
                                    <StaticImage src="../images/rawhide.png" alt="Rawhide" />
                                </span>
                                <span className="color-name">Rawhide</span>
                            </ColorBox>
                        </ColorItemList>
                        <ColorItemList className="color-item">
                            <ColorBox>
                                <span className="color-image">
                                    <StaticImage src="../images/bright-red.png" alt="Bright Red" />
                                </span>
                                <span className="color-name">Bright Red</span>
                            </ColorBox>
                        </ColorItemList>
                        <ColorItemList className="color-item">
                            <ColorBox>
                                <span className="color-image">
                                    <StaticImage src="../images/quaker-gray.png" alt="Quaker Gray" />
                                </span>
                                <span className="color-name">Quaker Gray</span>
                            </ColorBox>
                        </ColorItemList>
                        <ColorItemList className="color-item empty">
                            <ColorBox>&nbsp;</ColorBox>
                        </ColorItemList>
                        <ColorItemList className="color-item">
                            <ColorBox>
                                <span className="color-image">
                                    <StaticImage src="../images/royal-blue.png" alt="Royal Blue" />
                                </span>
                                <span className="color-name">Royal Blue</span>
                            </ColorBox>
                        </ColorItemList>
                        <ColorItemList className="color-item">
                            <ColorBox>
                                <span className="color-image">
                                    <StaticImage src="../images/pewter-gray.png" alt="Pewter Gray" />
                                </span>
                                <span className="color-name">Pewter Gray</span>
                            </ColorBox>
                        </ColorItemList>
                        <ColorItemList className="color-item">
                            <ColorBox>
                                <span className="color-image">
                                    <StaticImage src="../images/burnished-slate.png" alt="Burnished Slate" />
                                </span>
                                <span className="color-name">Burnished Slate</span>
                            </ColorBox>
                        </ColorItemList>
                        <ColorItemList className="color-item empty">
                            <ColorBox>&nbsp;</ColorBox>
                        </ColorItemList>
                        <ColorItemList className="color-item">
                            <ColorBox>
                                <span className="color-image">
                                    <StaticImage src="../images/evergreen.png" alt="Evergreen" />
                                </span>
                                <span className="color-name">Evergreen</span>
                            </ColorBox>
                        </ColorItemList>
                        <ColorItemList className="color-item">
                            <ColorBox>
                                <span className="color-image">
                                    <StaticImage src="../images/white.png" alt="White" />
                                </span>
                                <span className="color-name">White</span>
                            </ColorBox>
                        </ColorItemList>
                        <ColorItemList className="color-item">
                            <ColorBox>
                                <span className="color-image">
                                    <StaticImage src="../images/clay.png" alt="Clay" />
                                </span>
                                <span className="color-name">Clay</span>
                            </ColorBox>
                        </ColorItemList>
                        <ColorItemList className="color-item empty">
                            <ColorBox>&nbsp;</ColorBox>
                        </ColorItemList>
                        <ColorItemList className="color-item">
                            <ColorBox>
                                <span className="color-image">
                                    <StaticImage src="../images/sandstone.png" alt="Sandstone" />
                                </span>
                                <span className="color-name">Sandstone</span>
                            </ColorBox>
                        </ColorItemList>
                        <ColorItemList className="color-item">
                            <ColorBox>
                                <span className="color-image">
                                    <StaticImage src="../images/black.png" alt="Black" />
                                </span>
                                <span className="color-name">Black</span>
                            </ColorBox>
                        </ColorItemList>
                        <ColorItemList className="color-item">
                            <ColorBox>
                                <span className="color-image">
                                    <StaticImage src="../images/earth-brown.png" alt="Earth Brown" />
                                </span>
                                <span className="color-name">Earth Brown</span>
                            </ColorBox>
                        </ColorItemList>
                    </ColorList>
                </GridRight>
            </Grid>
            <HelpText><strong>Disclaimer:</strong> Please note that the images displayed are for reference purposes only. The actual color may vary from what is shown due to factors including screen resolution, printer quality, and printer settings.</HelpText>
            </Container>
        </Section>
        {/* <ProductCarousel data={data.allContentfulProduct.edges} /> */}
        </Layout> 
    );
};
export default ColorPage;

// export const pageQuery = graphql`
//   query ColorOptionPageQuery {
//     allContentfulProduct(sort: { fields: skuNumber, order: ASC }) {
//       edges {
//         node {
//           id
//           url
//           rto
//           financingAvailable
//           skuNumber
//           title
//           image {
//             gatsbyImageData(quality: 50, placeholder: BLURRED)
//             fluid {
//               srcWebp
//             }
//           }
//           price
//           width
//           length
//           height
//           city
//           state
//           zipcode
//           location {
//             lat
//             lon
//           }
//           buildingCondition
//           description {
//             childMarkdownRemark {
//               html
//             }
//           }
//           productCategories {
//             title
//           }
//           buildingTags
//           roofStyleType
//           manufacturer
//           gaugeTubing
//           gaugePanels
//         }
//       }
//     }
//   }
// `;