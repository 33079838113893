import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import {Section, Container } from "../components/Section"
import { HeroBanner, HeroImage } from "../components/HeroBanner"
import ContactBanner from "../images/contact-banner.jpg"
import ContactInfo from "../components/ContactInfo"
import JoinForm from "../components/JoinForm"
import Map from "../components/Map"
import BreakpointUp from "../components/Media/BreakpointUp"

const ContactGrid = styled.div`
  display:flex;
  flex-wrap:wrap;
  justify-content: space-between;
  margin:0 -15px;
`
const ContactLeft = styled.div`
  position:relative;
  width:100%;
  padding:0 15px;
  @media(min-width:992px){
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  } 
  @media(min-width:1200px){
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  } 
  .form{
    margin-bottom:30px;
    @media(min-width:992px){
      margin-bottom:0;
    }
  }
  .form-title{text-transform:uppercase;}
  
`
const ContactRight = styled.div`
  position:relative;
  width:100%;
  padding:0 15px;
  @media(min-width:992px){
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  } 
  @media(min-width:1200px){
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  } 
  .list-info{
    .list-title{
      font-weight:700;
      color:#373636;
      margin-bottom: 20px;

      font-size: 18px;
      line-height: 28px;
      @media (min-width: 576px) {
        font-size: 20px;
        line-height: 30px;
      }
      @media (min-width: 768px) {
        font-size: 26px;
        line-height: 36px;
      }
      @media (min-width: 992px) {
        font-size: 32px;
        line-height: 42px;
        margin-bottom: 40px;
      }
    }
    .list-item{
      color:#373636;
      .icon{
        svg{fill:#373636;}
      }
      a{
        color:#373636;
        &:after, &:before{
          background: rgba(233, 36, 42, 0.4);
        }
        &:hover{
          color:#E9242A;
        }
      }
    }
  }
`

const BannerBottomCaption = styled.div`  
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
  margin:0 0 30px;
  ${BreakpointUp.md`       
    margin:0 -15px 60px;
  `}
`
const Address = styled.address`
  box-shadow: 0 10px 45px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin-bottom: 0;
  font-style: inherit;
  padding: 30px 15px;
  position: relative;
  width: 100%;  
  margin-top: 15px;
  ${BreakpointUp.md`       
    margin:0 15px;
    + address{
      margin-top:0;
    }
  `}
  ${BreakpointUp.md`       
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  padding: 30px 20px;
  `}
  ${BreakpointUp.lg`    
      padding:50px 30px;
  `}
  > strong {
  display: block;
  color: #e9242a;
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  ${BreakpointUp.lg`    
    font-size:28px;
    line-height:38px;
    font-weight:900;
  `}
  }
  + address {
    border-top: 1px solid #ebedf3;
    ${BreakpointUp.md`           
      border-top:none;
      border-left:1px solid #EBEDF3;
   `}
  }
`
const ListInfo = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  ${BreakpointUp.lg`            
    font-size:18px;
    font-weight:700;
  `}
`
const ListInfoItem = styled.li`
  margin: 0;
  padding: 0;
  + li {
    margin-top: 15px;
  }
  .label {
    color: #595959;
    font-weight: 500;
    min-width: 80px;
    display: block;
    font-size: 14px;
    line-height: 18px;
    ${BreakpointUp.sm`        
      font-size:16px;
      line-height:20px;
      display: inline-block;
    `}
  }
  strong {
    color: #000;
  }
  a {
    color: #000;
    font-weight: 700;
    &:hover {
      color:#e9242a;
    }
    + span {
      margin-left: 10px;
    }
  }
`
const BadgeStatic = styled.span`
  background-color: #ebeef6;
  color: #444;
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  border-radius: 12px;
  padding: 4px 14px;
  line-height: 18px;
`

const ContactPage = ({ location }) => {
  const breadcrumbSchema = {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Home Page",
        item: "https://www.engineeredmetalbuildings.com/",
      },
      {
        "@type": "ListItem",
        position: 2,
        name: "Contact Us",
        item: `https://www.engineeredmetalbuildings.com/contact-us/`,
      },
    ],
  };
  const commonSchema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "image": "https://www.engineeredmetalbuildings.com/static/b81f2bb5f87a3992879586deaa81d02f/88ed0/logo.webp",
    "url": "https://www.engineeredmetalbuildings.com/",
    "sameAs": [
      "https://maps.app.goo.gl/yabxaKrf3LRjxD9F8",
      "https://www.embmetalbuildings.com",
      "https://www.engineeredmetalbuildings.com/idaho/"
    ],
    "logo": "https://www.engineeredmetalbuildings.com/static/b81f2bb5f87a3992879586deaa81d02f/88ed0/logo.webp",
    "name": "EMB Metal Buildings, LLC",
    "description": "At EMB Metal Buildings, we provide a wide range of strong, durable, and affordable structures to meet all your storage needs. Order your building today!",
    "email": "sales@embmetalbuildings.com",
    "telephone": "+1-479-223-5995",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "340 Centennial Drive",
      "addressLocality": "Heyburn",
      "addressRegion": "ID",
      "postalCode": "83336",
      "addressCountry": "US"
    }
  }
 

  const schema = [breadcrumbSchema, commonSchema]
  return(
    
    <Layout location={location}>
      <Seo title="Contact Us | Engineered Metal Building" description="Need help with your building? Give us a call at (479) 223-5995 or email us at sales@embmetalbuildings.com, and we will help you get the perfect metal structure according to your need and budget. Call now!" schemaMarkup={schema} />
      <HeroBanner>
        <HeroImage bgImg={`url(${ContactBanner})`}></HeroImage>
      </HeroBanner>
      <Section ept="60px" epb="60px" xpt="40px" xpb="40px" pt="30px" pb="30px" bgColor="#fff" className="section-contact">
      <Container>
          <BannerBottomCaption>
            <Address>
              <strong>SALES DEPARTMENT</strong>
              <ListInfo>
                <ListInfoItem>
                  <div className="label">Toll Free :</div>
                  <a href="tel:8335442957" aria-label="phone number">(833) 544-2957</a>{" "}
                  <BadgeStatic>New Customers</BadgeStatic>
                </ListInfoItem>
                <ListInfoItem>
                  <div className="label">Local :</div>
                  <a href="tel:4792235995" aria-label="phone number">(479) 223-5995</a>
                </ListInfoItem>
                <ListInfoItem>
                  <div className="label">Email :</div>
                  <a href="mailto:sales@embmetalbuildings.com">
                    <u>sales@embmetalbuildings.com</u>
                  </a>
                </ListInfoItem>
              </ListInfo>
            </Address>
            <Address>
              <strong>CUSTOMER SERVICE</strong>
              <ListInfo>
                <ListInfoItem>
                  <div className="label">Toll Free :</div>
                  <a href="tel:8006254083" aria-label="phone number">(800) 625-4083</a>{" "}
                  <BadgeStatic>Existing Customers</BadgeStatic>
                </ListInfoItem>
                <ListInfoItem>
                  <div className="label">Local :</div>
                  <a href="tel:2089449189" aria-label="phone number">(208) 944-9189</a>
                </ListInfoItem>
                <ListInfoItem>
                  <div className="label">Email :</div>
                  <a href="mailto:customerservice@embmetalbuildings.com">
                    <u>customerservice@embmetalbuildings.com</u>
                  </a>
                </ListInfoItem>
              </ListInfo>
            </Address>
          </BannerBottomCaption>
        </Container>
        <Container>
          <ContactGrid>
              <ContactLeft><JoinForm location={location} thanksURL="/thank-you-contact-us" /></ContactLeft>
              <ContactRight>
                <ContactInfo /> 
                <Map />
              </ContactRight>
          </ContactGrid>
        </Container>
      </Section>
    </Layout>
  )
}


export default ContactPage
