import React from "react";

export const ArrowLeftIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="10"
      viewBox="0 0 12 10"
    >
      <path
        d="M6.082 0l-1.52 4.862 1.52 5.138-6.082-5zM4.398 4.166h7.602v1.667h-7.602z"
        fill={props.fill}
      />
    </svg>
  );
};

export const ArrowRightIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="10"
      viewBox="0 0 12 10"
    >
      <path
        d="M5.918 0l1.52 4.862-1.52 5.138 6.082-5zM0 4.166h7.602v1.667h-7.602z"
        fill={props.fill}
      />
    </svg>
  );
};
